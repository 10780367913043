import Header from '../Nav/Nav';
import Footer from '../Footer/Footer';
import './Testimonials.scss';

function Testimonials() {
  return (
    <>
      <Header />
      <div className='page-content-container'>
        <div className='testimonial-content'>

          <div className='row'>
            <h1 className="testimonial-title">Testimonials</h1>
          </div>

          <div className='row testimonial-section-A'>
            <div className="col text-container">
              <p className='testimonial-text'>
                <em>
                  “I was very excited to discover Frannie as I was struggling to work out which phase I was at in my journey,
                  along with plenty of other queries. Without hesitation, I booked a session with Frannie, and I’m so pleased
                  I did. I instantly felt comfortable sharing my journey with her. Her knowledge, experience, and intuition
                  offered me wonderful insights, guidance, and so much reassurance. She was able to identify where I was in my
                  journey and offer me practical skills to support me through the rest of my awakening. Frannie has given me so
                  much more clarity, acceptance, and confidence to move forward. I feel so much more at ease with my experience
                  so far, and I can now see a clearer road ahead. Thank you, Frannie, for your invaluable guidance and support.”
                </em>
              </p>
              <p className='testimonial-text'>
                <strong>- Sam Harper</strong>
              </p>
            </div>
          </div>

          <div className='row testimonial-section-B'>
            <div className="col text-container">
              <p className='testimonial-text'>
                <em>
                  "A spiritual coach, often known as a ‘Life Coach for Spiritual People,’ plays a vital role in helping clients
                  find peace, harmony, happiness, and joy. They inspire individuals to focus on their emotions and inner voice,
                  fulfilling personal needs. Whether you’re at a crossroads, seeking clarity, or yearning for deeper connection,
                  Frannie offers a guiding light. She offers a space where people from all walks of life can explore their inner
                  world, uncover hidden potentials, and embrace change with an open heart. Regardless of background or experiences,
                  Frannie becomes a compass for personal evolution."
                </em>
              </p>
              <p className='testimonial-text'>
                <strong>- Linda Hoagland, LMSW, RRT</strong>
              </p>
            </div>
          </div>

          <div className='row testimonial-section-A'>
            <div className="col text-container">
              <p className='testimonial-text'>
                <em>
                  “I have known Frannie, socially, since approximately 2019. She and I had been on individual spiritual paths
                  to self-discovery, healing and peace when we reconnected. We are now united in our quest for greater understanding,
                  growth and connection to our Higher Power, other people and to the universe. Witnessing her spiritual growth has
                  been a great joy and inspiration to me. She exudes kindness and positivity. I believe that kindness and positivity,
                  along with her insight, experience, growth, intuition, and empathy make her uniquely qualified to guide others to their greater good.”
                </em>
              </p>
              <p className='testimonial-text'>
                <strong>- Jeanne Fernandez, RAC, Registered Addiction Counselor</strong>
              </p>
            </div>
          </div>

          <div className='row testimonial-section-B'>
            <div className="col text-container">
              <p className='testimonial-text'>
                <em>
                  "Frannie Stevens has been a friend for several years, having met weekly with a group to discuss personal growth, 
                  transformation and developing consciousness. I was drawn to Frannie as a person because of her calm, uplifting presence. 
                  She has an ability to listen deeply and synthesize information in a way that clarifies and takes it to another level. 
                  She is grounded and self-aware, responding to others in a way that is based in love, but is also honest and unmasked. 
                  Her feedback is nurturing but also revealing, to help one look within and see his/her own unconscious patterns of showing up in the world. 
                  Frannie Stevens walks a path of integrity, living her beliefs and offering wise counsel to other women from a place that is 
                  grounded in the wisdom of many years of practice and experience."
                </em>
              </p>
              <p className='testimonial-text'>
                <strong>- Elaine Taylor, Ph.D.</strong>
              </p>
            </div>
          </div>

        </div>
      </div>
      <Footer />
    </>
  );
}

export default Testimonials;