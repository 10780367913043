import './Footer.scss';

function Footer() {

  const year = new Date().getFullYear();
  return (
    <div className='footer-container'>

      <div className='row'>
        <div className='footer-item-container mobile'>
          <div className='footer-text'>
            <p className='col-12 bold-footer-text'>Phone: </p>
            <p className='col-12'>469-387-0800</p>
          </div>
          <div className='footer-text'>
            <p className='col-12 bold-footer-text'>Email: </p>
            <p className='col-12'>francine.stevens@ourspiritualmindset.com</p>
          </div>
        </div>

        <div className='footer-item-container desktop'>
          <p className='footer-text'>
            <span className='bold-footer-text'>Phone: </span>
            469-387-0800
          </p>
          <p className='footer-text'>
            <span className='bold-footer-text'>Email: </span>
            francine.stevens@ourspiritualmindset.com
          </p>
        </div>
      </div>

      <div className='row'>
        <div className='footer-item-container'>
          <p className='footer-text legal'>
            Our Spiritual Mindset LLC neither diagnosis, nor offers medication of any kind. Our Spiritual Mindset LLC is not a medical
            service and will not offer a diagnosis or treatment of medical conditions.
          </p>
          <br />
          <p className='footer-text'>
            ©{year} Our Spiritual Mindset LLC.
            <br />
            All Rights Reserved.
          </p>
        </div>
      </div>

    </div>
  );
}

export default Footer;