import Header from '../Nav/Nav';
import Footer from '../Footer/Footer';
import './WhatToExpect.scss';

function WhatToExpect() {
  return (
    <>
      <Header />
      <div className='page-content-container'>
        <div className="page-content">

          <div className='row'>
            <h1 className="page-title">What to Expect</h1>
          </div>

          <div className='row'>
            <div className="col text-container">
              <h4 className="page-sub-title">Unveiling the Journey: What Happens in Our Sessions</h4>
              <p className='section-text'>
              During each session my aim is to create a safe and understanding space designed to cater to your needs and goals. Whether you’re trying to find peace after a difficult time in your life, navigate life’s disappointments, soften a hardened heart or something else entirely, I am here to offer my sincere guidance and empathy. I will adapt to your circumstances, and provide intuitive insights to help illuminate your path forward. Each session is unique, much like you. I start with a brief check-in and utilize techniques such as prayer, meditation or lessons. As the journey transcends the traditional and enters the spiritual, you will find yourself immersed in a deeply personal process of spiritual healing and growth. And, in case you’re wondering, yes, the same level of spiritual connection and healing can and does occur even though I’m not physically present with you.
              </p>

              <div className="col text-container">
                <h4 className="page-sub-title">At the conclusion of the sessions, some of the transformations you can anticipate include:</h4>
                <ul className='page-list-container'>
                  <li>A heightened sense of inner peace and well-being.</li>
                  <li>A deeper understanding of your spiritual self.</li>
                  <li>Deep insights that help you surmount personal challenges, whether they're related to love for yourself, others or personal growth.</li>
                  <li>Positive shifts in energy, bringing about improved life experiences.</li>
                  <li>A stronger connection with your inner self, your intuition and a higher power.</li>
                </ul>
                <p className='section-text'>
                  My approach is not about grand promises or unrealistic claims. It’s about using my spiritual gifts to aid you in finding your path, restoring hope, and initiating healing. This process isn’t about setting unrealistic expectations, but rather about fostering a deeper bond with your spiritual self and empowering you to overcome your challenges. I hold a firm belief in the power of positive energy and I am dedicated to helping you uncover and harness this power within yourself.
                </p>
              </div>
            </div>
          </div>

        </div>
      </div>
      <Footer />
    </>
  );
}

export default WhatToExpect;