import Header from '../Nav/Nav';
import Footer from '../Footer/Footer';
import './HowToPrepare.scss';

function HowToPrepare() {
  return (
    <>
      <Header />
      <div className='page-content-container'>
        <div className="page-content">

          <div className='row'>
            <h1 className="page-title">How to Prepare for a Session</h1>
          </div>

          <div className='row'>
            <div className="col text-container">
              <h4 className="page-sub-title">To make the most of each of our sessions, a little preparation can help:</h4>
              <ul className='page-list-container'>
                <li>Sessions are conducted over the phone or virtually, allowing you to choose a comfortable and familiar setting. Find a quiet place where you won't be distracted during our call. Have a tablet for notes.</li>
                <li>Earbuds or a headset can help with clarity and freedom of movement during the call.</li>
                <li>Relax. This process is about tapping into your spiritual energy and healing, and being relaxed can help facilitate that process.</li>
                <li>If you wish, you can write down some questions or thoughts you'd like to explore during our session and email them to me. However, this is entirely optional.</li>
              </ul>
            </div>
          </div>

          <div className='row'>
            <div className="col text-container">
              <h4 className="page-sub-title">Follow-up Sessions (optional)</h4>
              <p className='section-text'>
                After our initial three sessions, if you feel like you want to continue working with me, we can discuss the best way to proceed. The goal is to meet your unique needs and to continue supporting you on your path to healing and growing. A commitment on your part is required for growth. We will create a toolbox of solutions to refer to on your spiritual journey.  You will continue to practice these techniques so you can live a mindful, peaceful, and present moment life.
              </p>
            </div>
          </div>

        </div>
      </div>
      <Footer />
    </>
  );
}

export default HowToPrepare;