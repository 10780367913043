import './Nav.scss';
import logo from '../../assets/logos/logo.png';

function Nav() {
  return (
    <nav className="navbar navbar-expand-lg navbar-dark">
      <div className="container-fluid">

        <img src={logo} alt='logo' className='nav-logo' />
        <a className="navbar-brand nav-title" href="/">
          Our Spiritual Mindset
          <span className="llc">LLC</span>
        </a>

        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#nav-content"
          aria-controls="nav-content"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse nav-link-list" id="nav-content">
          <div className="navbar-nav mb-2 mb-lg-0">
            <div className="nav-item">
              <a className="nav-link" href="/contact">Contact</a>
            </div>
            <div className="nav-item">
              <a className="nav-link" href="/about">About Me</a>
            </div>
            <div className="nav-item">
              <a className="nav-link" href="/how">How It Works</a>
            </div>
            <div className="nav-item">
              <a className="nav-link" href="/expect">What To Expect</a>
            </div>
            <div className="nav-item">
              <a className="nav-link" href="/prepare">How To Prepare</a>
            </div>
            <div className="nav-item">
              <a className="nav-link" href="/testimonials">Testimonials</a>
            </div>
          </div>
        </div>

      </div>
    </nav>
  );
}

export default Nav;