import Header from '../Nav/Nav';
import Footer from '../Footer/Footer';
import picture from '../../assets/images/picture6.jpg';
import './About.scss';

function About() {
  return (
    <>
      <Header />
      <div className='page-content-container'>
        <div className='page-content'>

          <div className='row'>
            <h1 className='page-title'>About Me</h1>
          </div>

          <div className='row'>
            <div className='col-12 col-lg-6 text-container'>
              <p className='section-text'>
                I am sensitive. I suffered most of my life with emotional pain. First, by feeling rejected by my childhood nuclear
                family and then later in life by a marriage that felt emotionally abusive to me. I was told I was unwanted and not loved; that I was a mistake from the beginning and had no value. When I was a teenager, an awareness came to me from a Source outside of myself that I am wanted and loved. 
                This awareness guided me to a path of self-love and self-acceptance.
                I learned to accept myself fully and unconditionally as a beautiful child of the Universe. This kept me nourished and allowed me to create
                childhood solutions for survival. However, childhood solutions don’t work in an adult world.
              </p>
            </div>

            <div className="col-12 col-lg-6">
              <div className="about-photo-container">
                <img src={picture} alt='Francine' className='about-photo' />
              </div>
            </div>
          </div>

          <div className='row'>
            <div className="col text-container">
              <p className='section-text'>
                During a 32-year marriage, I suffered in silence due to lack of family support. I became deeply depressed for over 12 years. Life was torture! I couldn’t force myself to get out of bed, bathe or brush my teeth. Cooking became impossible. I could find no relief and I had no skills to protect myself. 
              </p>
              <p className='section-text'>
                Although therapy was a help, it was not a cure all. I needed techniques and skills that would change my negative thinking and ruminating. I also needed to learn how to love and empower myself. 
              </p>
              <p className='section-text'>
              One day when I was alone and in a state of intense pain, a voice in my head said to me “You have suffered enough.” That’s when I knew it was time to take action.
              </p>
              <p className='section-text'>
              In 2015, I began researching and studying to find the skills I needed to release all negative stored energy and pain. I came up with solutions that brought me to the other side of my depression.  Even in my deepest pain I kept thinking that I want to help other women recover from their pain. I want to help women find the treasure of self-love. 
              </p>
              <p className='section-text'>
              Women helping women survive was my exact thought. I want to help women stop living in negative energy and suffering. I want to give them the tools I found to heal, to get to the other side of pain. So, I am revealing a spiritual journey!
              </p>
            </div>
          </div>

        </div>
      </div>
      <Footer />
    </>
  );
}

export default About;