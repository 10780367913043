import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import Home from './components/Home/Home';
import About from './components/About/About';
import HowItWorks from './components/HowItWorks/HowItWorks';
import WhatToExpect from './components/WhatToExpect/WhatToExpect';
import HowToPrepare from './components/HowToPrepare/HowToPrepare';
import Testimonials from './components/Testimonials/Testimonials';
import Contact from './components/Contact/Contact';
import './index.scss';

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/about",
    element: <About />,
  },
  {
    path: "/how",
    element: <HowItWorks />,
  },
  {
    path: "/expect",
    element: <WhatToExpect />,
  },
  {
    path: "/prepare",
    element: <HowToPrepare />,
  },
  {
    path: "/testimonials",
    element: <Testimonials />,
  },
  {
    path: "/contact",
    element: <Contact />,
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);


