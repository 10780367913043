import Header from '../Nav/Nav';
import Footer from '../Footer/Footer';
import './Contact.scss';

function Contact() {
  return (
    <>
      <Header />
      <div className='page-content-container'>
        <div className="page-content">
          <div className='row'>
            <h1 className="page-title">Contact</h1>
          </div>

          <div className='row'>
            <div className="col text-container">
              <p className='section-text'>
                If you feel ready to take a step on your journey towards healing and self-discovery, I am here to walk with you.
                This process is about you – finding inner peace and joy and rebuilding your self-confidence
                and faith in yourself. Let’s navigate your path to spiritual healing together. Before signing up new clients,
                I like to have a chat first. Consider it an opportunity to get to know each other and for me to answer any questions
                you may have. The free, initial conversation usually lasts 15-30 minutes. Please contact me to schedule an appointment 
                that’s convenient for you. I am looking forward to meeting you!
              </p>
            </div>
          </div>


          <div className='row'>
            <div className="col text-container">
              <div className="section-text">
                <strong>Email: </strong>
                <br />
                francine.stevens@ourspiritualmindset.com
              </div>
              <div className="section-text">
                <strong>Phone: </strong>
                <br />
                469-387-0800
              </div>
            </div>
          </div>
        </div>

      </div>
      <Footer />
    </>
  );
}

export default Contact;