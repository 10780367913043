import Header from '../Nav/Nav';
import Footer from '../Footer/Footer';
import homePic from '../../assets/images/picture1.jpg';
import logo from '../../assets/logos/logo.png';

import './Home.scss';

function Home() {
  return (
    <>
      <Header />
      <div className='landing-content'>

        <div className='row'>
          <div className="col landing-text">
            <h1 className="landing-title">Spiritual Healing</h1>
            <div className="landing-secondary">Let Me Guide You to Heal from Within.</div>
            <div className="landing-secondary">Find Balance, Peace & Clarity in Your Life.</div>
            <img src={logo} alt='OSM Logo' className='landing-logo' />
          </div>
        </div>

        {/* <div className='row'>
          <div className="col">
            <img src={logo} alt='OSM Logo' className='landing-logo' />
          </div>
        </div> */}
      </div>

      <div className='section-A'>
        <div className='row section-content'>

          <div className="col-12 col-xl-6">
            <div className='section-photo-container'>
              <img src={homePic} alt='Francine' className='home-photo' />
            </div>
          </div>

          <div className="col-12 col-xl-6 text-container">
          <h2 className="section-title">Help is Here</h2>
            <ul className='section-list-container'>
              <li>Do you often feel overwhelmed or stressed out?</li>
              <li>Do you feel like you're going in circles?</li>
              <li>Are you finding it hard to let go of past pain?</li>
              <li>Do you feel misunderstood?</li>
              <li>Do you pick up on other people's negative energy?</li>
              <li>Is it difficult for you to let go of fears and worries?</li>
            </ul>
            <p className='secondary-text'>
              I work with individuals who are feeling unhappy or deflated,
              those struggling with life's heartbreaks, and those in search of understanding their lives.
              Together, we’ll work to open your mind and tap into your spiritual energy, aiding
              you to break free from the energetic hindrances that have been holding you back.
            </p>
          </div>

        </div>
      </div>

      <div className='section-B'>
        <div className='row section-content'>

          <div className="col text-container">
            <h2 className="section-title">Free Consultation</h2>
            <p className="consultation-text">
              Join me in a 15-30 minute session to assess whether this program is for you.
            </p>
            <a href={'/how'} className='btn home-btn'>
              <div className="section-btn-text">How it Works</div>
            </a>

          </div>
        </div>
      </div>

    <Footer />
    </>
  );
}

export default Home;